// src/layouts/Main.js

const Main = (props) => {
  return (
    <main className="main pb-5">
      {props.children}
    </main>
  );
};

export default Main;
